import { useMediaQuery } from 'styled-breakpoints/use-media-query';
import { useTheme } from 'styled-components';
import { Container, Section } from 'components/Container';
import { Header } from './Header.styled';
import { DesktopTimeline } from './DesktopTimeline';
import { MobileTimeline } from './MobileTimeline';
import { timelineContent } from './timelineContent';

/**
 * The Timeline present in the rebrand of the site.
 *
 * NOTE: this is _incredibly_ delicate in regards to its styling, any changes made to this will likely
 * involve digging around and tweaking or fixing various styles lest the entire is broken.
 *
 * Good luck if you ever intend to make this properly dynamic.
 */

export const Timeline = () => {
  const theme = useTheme();
  const showMobileTimeline = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container>
      <Section>
        <Header>Our story</Header>

        {showMobileTimeline ? (
          <MobileTimeline timelineContent={timelineContent} />
        ) : (
          <DesktopTimeline timelineContent={timelineContent} />
        )}
      </Section>
    </Container>
  );
};
