import { Line } from 'components/Line';
import {
  NodeContainer,
  OffsetContainer,
  PointerContainer,
  TimelineContainer,
} from './Containers.styled';
import { Content } from './Content.styled';
import { Node } from './Node.styled';
import { Label } from './Label.styled';
import { Pointer } from './Pointer.styled';
import type { FC } from 'react';
import type { TimelineProps } from './types';

export const DesktopTimeline: FC<TimelineProps> = ({ timelineContent }) => (
  <TimelineContainer>
    <Line>
      <NodeContainer index={0}>
        <Node index={0} />
        <OffsetContainer index={0}>
          <PointerContainer>
            <Pointer index={0} />
            <Label index={0}>{timelineContent[0].date}</Label>
          </PointerContainer>
          <Content>{timelineContent[0].content}</Content>
        </OffsetContainer>
      </NodeContainer>

      <NodeContainer index={1}>
        <OffsetContainer index={1}>
          <PointerContainer>
            <Label index={1}>{timelineContent[1].date}</Label>
            <Pointer index={1} />
          </PointerContainer>
          <Content>{timelineContent[1].content}</Content>
        </OffsetContainer>
        <Node index={1} />
      </NodeContainer>

      <NodeContainer index={2}>
        <Node index={2} />
        <OffsetContainer index={2}>
          <PointerContainer>
            <Pointer index={2} />
            <Label index={2}>{timelineContent[2].date}</Label>
          </PointerContainer>
          <Content>{timelineContent[2].content}</Content>
        </OffsetContainer>
      </NodeContainer>

      <NodeContainer index={3}>
        <OffsetContainer index={3}>
          <PointerContainer>
            <Label index={3}>{timelineContent[3].date}</Label>
            <Pointer index={3} />
          </PointerContainer>
          <Content>{timelineContent[3].content}</Content>
        </OffsetContainer>
        <Node index={3} />
      </NodeContainer>

      <NodeContainer index={4}>
        <Node highlighted index={4} />
        <OffsetContainer index={4}>
          <PointerContainer>
            <Pointer highlighted index={4} />
            <Label index={4}>{timelineContent[4].date}</Label>
          </PointerContainer>
          <Content>{timelineContent[4].content}</Content>
        </OffsetContainer>
      </NodeContainer>
    </Line>
  </TimelineContainer>
);
