import type { TimelineContent } from './types';

export const timelineContent: TimelineContent[] = [
  {
    date: '2006',
    content:
      'Tired of spreadsheet calculations, a Kiwi father/son duo find a better way to track shares.',
  },
  {
    date: '2008',
    content:
      'We are online. Our first paying customer starts tracking performance with Sharesight.',
  },
  {
    date: '2013',
    content: 'Our team expands into a second office across the Tasman in Sydney.',
  },
  {
    date: '2015',
    content: 'Sharesight launches internationally with a $2m capital raise from our customer base.',
  },
  {
    date: 'Today',
    content: 'Now a leading fintech company, Sharesight helps hundreds of thousands of investors.',
  },
];
