import styled from 'styled-components';
import { getDimension } from 'styled/helpers/getDimension';

export interface LabelProps {
  index: number;
}

/** A styled `h3` tag for displaying the label on a Timeline node, positions itself based upon the index of the node */
export const Label = styled.h3<{ index: number }>`
  margin: 0 ${getDimension('size4')} 0 0;
  ${({ index }) => {
    const placement = index % 2 ? 'flex-end' : 'flex-start';
    return {
      'align-self': placement,
    };
  }}
`;
