import styled from 'styled-components';
import { lighten } from 'polished';

import { getDimension } from 'styled/helpers/getDimension';

/**
 * The "nodes" in the Timeline component. \
 * These can be presented `highlighted` form, which increases the size of the node,
 * adds an additonal `box-shadow` and adjusts positioning accordingly.
 *
 * Additionally both forms of the node will change their position based upon index.
 */
export const Node = styled.div<{
  highlighted?: boolean;
  index: number;
}>`
  border-radius: 50%;
  background-color: ${props => props.theme.colors.orange};
  width: ${({ highlighted, theme }) =>
    highlighted ? getDimension('size4')({ theme }) : getDimension('size3')({ theme })};
  height: ${({ highlighted, theme }) =>
    highlighted ? getDimension('size4')({ theme }) : getDimension('size3')({ theme })};

  box-shadow: ${({ highlighted, theme }) => {
    let shadow = `0 0 0 1px #fff, 0 0 0 ${getDimension('size2')({ theme })} ${lighten(
      0.35,
      theme.colors.orange
    )}`;

    /* For highlighted nodes we add an additonal shadow */
    if (highlighted) {
      shadow = `0 0 0 1px #fff, 0 0 0 ${getDimension('size2')({ theme })} ${lighten(
        0.2,
        theme.colors.orange
      )}, 0 0 0 ${getDimension('size4')({ theme })} ${lighten(0.35, theme.colors.orange)}`;
    }

    return shadow;
  }};

  margin-left: ${getDimension('size8')};

  ${({ index, highlighted, theme }) => {
    const margin = highlighted
      ? getDimension('size2')({ theme })
      : getDimension('size1')({ theme });
    const direction = index % 2 ? 'bottom' : 'top';

    return { [`margin-${direction}`]: `-${margin}` };
  }}

  z-index: 2;
`;
