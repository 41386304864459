import { useMemo } from 'react';
import type { FC } from 'react';
import type { TimelineProps } from './types';

export const MobileTimeline: FC<TimelineProps> = ({ timelineContent }) => {
  const renderTimelineContent = useMemo(
    () =>
      timelineContent.map(content => (
        <>
          <h2>{content.date}</h2>
          <p>{content.content}</p>
          <br />
        </>
      )),
    [timelineContent]
  );

  return <>{renderTimelineContent}</>;
};
