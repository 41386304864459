import styled from 'styled-components';
import { getDimension } from 'styled/helpers/getDimension';

export const TimelineContainer = styled.div`
  padding-bottom: ${getDimension('size15')};
`;

/**
 * The container that wraps each node. \
 * Including the node itself, the label, the pointer and the text. \
 * It's placement and `margin-left` are based upon the index of this node
 */
export const NodeContainer = styled.div<{
  index: number;
}>`
  display: flex;
  flex-direction: column;
  position: absolute;
  margin-left: ${({ index }) => `${5 + 15 * index}%`};
  ${({ index }) => {
    const placement = index % 2 ? 'flex-end' : 'flex-start';
    return { 'place-self': placement };
  }};
`;

/** The container that wraps the pointer, label and text. It's placement is based upon the index of the node */
export const OffsetContainer = styled.div<{ index: number }>`
  display: flex;
  flex-direction: row;
  align-items: ${({ index }) => (index % 2 ? 'flex-start' : 'flex-end')};
  margin-left: ${getDimension('size8')};
`;

/** The container that wraps the pointer and the label */
export const PointerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
